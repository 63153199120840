import { useAuth } from '../../../use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const auth = useAuth();
const actions = new Actions('skill', 'skills');

const customActions = {
  async LOAD_skillsBySkillSet({ commit, rootGetters }, skillSetId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('skills')
      .query({ $filter: `skillSetId eq ${skillSetId}`, $expand: 'skillItems' });
    commit('SET_skills', data);
    return data;
  },
  async SAVE_selectedSkills({ rootGetters, state }, skillSet) {
    const { selectedSkills } = state;
    const token = await auth.getAccessToken();
    const promises = selectedSkills.map((skill) => new Promise((resolve, reject) => {
      odata.o(rootGetters, token)
        .post('skills', {
          ...skill,
          skillSetId: skillSet.id,
        })
        .query()
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    }));

    const result = await Promise.all(promises);
    return result;
  },
};

export default { ...actions, ...customActions };
