export default {
  botTypes: [
    'AdjectiveBot',
    'SynonymBot',
    'KeywordBot',
    'ProfessorBot',
  ],
  trainingItems: [],
  trainingItem: undefined,
};
