import { useAuth } from '../../../use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const auth = useAuth();
const actions = new Actions('prompt', 'prompts');

const customActions = {
  async LOAD_prompts({ commit, rootGetters }, botName) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('prompts')
      .query({ $filter: `IsDeleted eq false AND BotName eq '${botName}'` });
    commit('SET_prompts', data);
    return data;
  },
};

export default { ...actions, ...customActions };
