import { o } from 'odata';
import authHub from '../authHub';
import errorHub from '../errorHub';

const odata = {
  o(rootGetters, token) {
    if (!token) {
      console.warn('no token found using fallback');
    }
    const accessToken = token ?? window.localStorage.getItem('access_token');
    const organization = rootGetters['organizations/getOrganization'];
    return o(`${process.env.VUE_APP_APIURL}/odata/`, this.getConf(accessToken, organization));
  },
  getConf: (token, organization) => ({
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      organizationid: `${organization?.id ?? 3}`,
    }),
    onError: (x, e) => {
      if (e.status === 401) {
        authHub.$emit('user.unauthorized');
      } else {
        errorHub.$emit('network.error');
      }
    },
  }),
  parseId: (id) => {
    let i = id;
    if (Number.isNaN(id)) {
      i = `'${id}'`;
    }
    return i;
  },
};
export { odata };

export default {};
