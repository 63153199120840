import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { defaultsDeep } from 'lodash';
// import { locales as authLocales } from '@codehq/voorraad-client-auth/src';
// import { locales as propertiesLocales } from '@codehq/voorraad-client-properties/src';
import localLocales from './locales';

const messages = defaultsDeep(localLocales);

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
});

export { i18n };
export { messages };
