export default {
  options: undefined,
  error: undefined,
  token: undefined,
  refresh_token: undefined,
  token_expires_in: undefined,
  token_expires: undefined,
  username: undefined,
  roles: [],
  userId: undefined,
  last_token_refresh_request: new Date(),
  provider: 'local',
  isLoggedIn: false,
};
