import { useAuth } from '../../../use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const auth = useAuth();
const actions = new Actions('dimension', 'dimensions');

const customActions = {
  async LOAD_dimensions({ rootGetters, commit }, subCompetencyId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('dimensions')
      .query({
        $filter: `IsDeleted eq false AND subCompetencyId eq ${subCompetencyId}`,
        $orderby: 'created desc',
        $expand: 'keywords($expand=KeywordAlternatives($filter=IsDeleted eq false)), questions($filter=IsDeleted eq false), tips($filter=IsDeleted eq false)',
      });
    commit('SET_dimensions', data);
    return data;
  },
};

export default { ...actions, ...customActions };
