/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import Cookies from 'js-cookie';

// Store functionality
import modules from './modules';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['app', 'auth', 'organizations'],
});

const vuexCookie = new VuexPersistence({
  restoreState: (key) => JSON.parse(Cookies.get(key) ?? '{}'),
  saveState: (key, state) => {
    const existingState = JSON.parse(Cookies.get(key) ?? '{}');
    // only set the cookie if the value is different
    if (existingState.searchCounter !== state.searchCounter) {
      Cookies.set(key, JSON.stringify(state), {
        expires: 1,
      });
    }
  },
  modules: ['trialInfo'],
});

Vue.use(Vuex);

// Create a new store
const store = new Vuex.Store({
  modules,
  plugins: [
    vuexCookie.plugin,
    vuexLocal.plugin,
  ],
});

export default store;

export const useStore = () => store;
