import store from '../store';

export default (to, from, next) => {
  const isLoggedIn = store.getters['auth/isLoggedIn'];

  // If route has a protection in metadata
  if (to.meta.isAuthenticated) {
    // If user has a token
    if (isLoggedIn) {
      // We allow to access page
      next(true);
    } else {
      console.info('user not authenticated, redirect to login');
      // Else we redirect to login
      next('/welcome');
    }
  } else {
    // Else if route hasn't any protection we allow to access page
    next(true);
  }
};
