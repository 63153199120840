import { useAuth } from '../../../use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const auth = useAuth();

const actions = new Actions('keyword', 'keywords');

const customActions = {
  async LOAD_keywords({ rootGetters, commit }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('keywords')
      .query({
        $filter: 'IsDeleted eq false',
        $orderby: 'created desc',
        $expand: 'Dimension($expand=SubCompetency($filter=IsDeleted eq false;$expand=Competency($filter=IsDeleted eq false)))',
      });
    commit('SET_keywords', data);
    return data;
  },
  async LOAD_keywordsByDimension({ rootGetters }, dimensionId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('keywords')
      .query({
        $filter: `IsDeleted eq false AND DimensionId eq ${dimensionId}`,
        $orderby: 'created desc',
        $expand: 'KeywordAlternatives',
      });
    return data;
  },
  async SEARCH_keywords({ rootGetters }, keywords) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .post('keywordSearch', {
        keywords,
      })
      .query();
    // commit('SET_keywords', data);
    return data;
  },
};

export default { ...actions, ...customActions };
