import { ref } from 'vue';
import { Auth } from '@codehq/aurora-auth';
import * as Msal from 'msal';

const localAuth = new Auth({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});

// const b2cPolicies = {
//   names: {
//     signUpSignIn: 'B2X_1_Skillosophia_Google',
//   },
//   authorities: {
//     signUpSignIn: {
//       authority: 'https://aimsinternational.co.za.b2clogin.com/aimsinternational.co.za.onmicrosoft.com/B2X_1_Skillosophia_Google',
//     },
//     authorityDomain: 'aimsinternational.co.za.b2clogin.com',
//   },
// };
export const useAuth = () => {
  const config = {
    auth: {
      clientId: process.env.VUE_APP_AZURE_IDENTITY_CLIENT_ID,
      authority: 'https://login.microsoftonline.com/organizations',
      // authority: b2cPolicies.authorities.signUpSignIn.authority,
      // knownAuthorities: [b2cPolicies.names.signUpSignIn],
      redirectUri: `${window.location.origin}/auth/login`,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  };

  const userAgentApplication = new Msal.UserAgentApplication(config);

  const isAuthenticated = ref(false);
  const user = ref(null);

  async function getScopedAccessToken(scopes) {
    const provider = window.localStorage.getItem('auth:provider');
    if (provider === 'local') {
      console.info('local provider');
      const token = await localAuth.getAccessToken();
      if (token) {
        console.info('token found in local storage');
        return token;
      }
      console.info('no token found in local storage');
      return null;
    }
    const tokenRequest = {
      scopes,
    };

    try {
      const tokenResponse = await userAgentApplication.acquireTokenSilent(tokenRequest);
      if (tokenResponse) {
        return tokenResponse.accessToken;
      }
    } catch (error) {
      console.error(error);
      // acquireTokenSilent failed, fallback to acquireTokenRedirect
      try {
        tokenRequest.redirectUri = `${window.location.origin}/auth/login`;
        const tokenResponseFallback = await userAgentApplication.acquireTokenRedirect(tokenRequest);
        window.localStorage.setItem('access_token', tokenResponseFallback.accessToken);
        return tokenResponseFallback.accessToken;
      } catch (innerError) {
        console.error(error);
      }
    }
    console.warn('no token found, returning nothing');
    return null;
  }

  async function getAccessToken() {
    const provider = window.localStorage.getItem('auth:provider');
    if (provider === 'local') {
      console.info('local provider');
      const token = await localAuth.getAccessToken();
      if (token) {
        console.info('token found in local storage');
        return token;
      }
      console.info('no token found in local storage');
      return null;
    }
    const scopes = [
      process.env.VUE_APP_AZURE_IDENTITY_SCOPE,
    ];
    return this.getScopedAccessToken(scopes);
  }

  async function handleLoginRedirect() {
    const account = await userAgentApplication.handleRedirectCallback((error) => {
      if (error) {
        console.error(error);
      }
    });
    if (account) {
      isAuthenticated.value = true;
      user.value = account;
      return true;
    }

    return false;
  }

  async function loginLocal(username, password) {
    return localAuth.login(username, password);
  }

  function login() {
    try {
      const loginRequest = {
        scopes: ['openid', 'profile', 'User.Read', 'offline_access', process.env.VUE_APP_AZURE_IDENTITY_SCOPE],
        redirectUri: `${window.location.origin}/auth/login`,
      };
      userAgentApplication.loginRedirect(loginRequest);
      isAuthenticated.value = true;
      user.value = userAgentApplication.getAccount();
    } catch (error) {
      console.error(error);
    }
  }

  async function logout() {
    userAgentApplication.clearCache();
    isAuthenticated.value = false;
    user.value = null;
  }

  return {
    isAuthenticated,
    user,
    login,
    loginLocal,
    logout,
    getAccessToken,
    getScopedAccessToken,
    userAgentApplication,
    handleLoginRedirect,
  };
};
