<template>
  <v-app>
    <Auth />
    <Navbar />
    <v-main>
      <transition name="fade">
        <router-view />
      </transition>
      <!--  -->
    </v-main>
    <Footer />
    <HelpMenu />
    <Feedback />
    <Toasts :text="message" color="success" />
    <Toasts :text="error" color="red" :timeout="-1" />
    <BasicToasts />
  </v-app>
</template>

<script>
import { components as auroraAppCore } from '@codehq/aurora-app-core';
import { mapActions, mapState } from 'vuex';
import { mdiCog, mdiHelpCircle, mdiArrowRight } from '@mdi/js';
import Auth from './components/Auth.vue';
import authHub from './authHub';
import errorHub from './errorHub';

import './styles/app.scss';
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import HelpMenu from './components/HelpMenu.vue';

const Feedback = () => import('./components/Feedback.vue');

const { BasicToasts, Toasts } = auroraAppCore;

export default {
  name: 'App',
  components: {
    Auth,
    BasicToasts,
    Feedback,
    Navbar,
    Footer,
    HelpMenu,
    // PushNotifications,
    Toasts,
  },
  data() {
    return {
      mdiCog,
      mdiHelpCircle,
      message: '',
      error: '',
      items: [
        { text: 'Be a team player', value: 1 },
        // Add more skills as needed
      ],
      mdiArrowRight,
    };
  },
  computed: {
    ...mapState('auth', ['isLoggedIn']),
  },
  created() {
    errorHub.$on('network.error', () => {
      this.$root.$emit('network.error');
    });
    authHub.$on('user.unauthorized', () => {
      this.LOGOUT_auth();
      if (this.$route.path !== '/auth/login') {
        this.$router.push('/auth/login');
      }
    });
    this.$root.$on('auth:login', () => {
      this.$log.info('User logged in');
      this.$router.push({ name: 'app-Init' });
      this.$root.$emit('toast:notify', 'Logged in successfully');
    });
    this.$root.$on('auth:logoff', () => {
      this.$log.info('User logged out');
      if (this.$route.path !== '/auth/login') {
        this.$router.push('/auth/login');
      }
      this.$root.$emit('toast:notify', 'Logged out successfully');
    });
  },
  destroyed() {
    authHub.$off('user.unauthorized');
    this.$root.$off('auth:login');
  },
  methods: {
    ...mapActions('auth', ['LOGOUT_auth']),
    logout() {
      this.LOGOUT_auth();
      this.$router.push('/auth/login');
    },
  },
};
</script>
<style>
main > div {
  padding: 20px;
}
.v-chip__content {
  color: black;
}
.version {
  position: absolute;
  bottom: 0;
  right: 20px;
}
</style>
