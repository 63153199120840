import { useAuth } from '../../../use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const auth = useAuth();
const actions = new Actions('tip', 'tips');

const customActions = {
  async LOAD_tips({ rootGetters, commit }, query) {
    const { questionId, dimensionId } = query;
    const arr = [];
    if (questionId) {
      arr.push(`questionId eq ${questionId}`);
    }
    if (dimensionId) {
      arr.push(`dimensionId eq ${dimensionId}`);
    }
    const filter = arr.join(' OR ');
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('tips')
      .query({
        $filter: `IsDeleted eq false AND (${filter})`,
        $orderby: 'created desc',
      });
    commit('SET_tips', data);
    return data;
  },
  async LOAD_subCompetencyTips({ rootGetters }, id) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('tips')
      .query({
        $filter: `IsDeleted eq false AND (SubCompetencyId eq ${id})`,
        $orderby: 'created desc',
      });
    return data;
  },
  async LOAD_dimensionTips({ rootGetters }, dimensionId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('tips')
      .query({
        $filter: `IsDeleted eq false AND DimensionId eq ${dimensionId}`,
        $orderby: 'created desc',
      });
    return data;
  },
};

export default { ...actions, ...customActions };
