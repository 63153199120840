import log4Javascript from 'log4javascript';

export const useLogging = () => {
  const log = log4Javascript.getLogger();
  let logLevel = log4Javascript.Level.WARN;
  switch (process.env.VUE_APP_LOGLEVEL) {
    case 'ALL':
      logLevel = log4Javascript.Level.ALL;
      break;
    case 'TRACE':
      logLevel = log4Javascript.Level.TRACE;
      break;
    case 'INFO':
      logLevel = log4Javascript.Level.INFO;
      break;
    case 'DEBUG':
      logLevel = log4Javascript.Level.DEBUG;
      break;
    case 'ERROR':
      logLevel = log4Javascript.Level.ERROR;
      break;
    case 'FATAL':
      logLevel = log4Javascript.Level.FATAL;
      break;
    case 'OFF':
      logLevel = log4Javascript.Level.OFF;
      break;
    case 'WARN':
    default:
      logLevel = log4Javascript.Level.WARN;
      break;
  }
  log.setLevel(logLevel);
  if (process.env.NODE_ENV === 'development') {
    const browserConsoleAppender = new log4Javascript.BrowserConsoleAppender();
    log.addAppender(browserConsoleAppender);
  }
  if (process.env.VUE_APP_LOGWINDOW === 'true') {
    const popUpAppender = new log4Javascript.PopUpAppender();
    popUpAppender.setFocusPopUp(true);
    popUpAppender.setNewestMessageAtTop(true);
    log.addAppender(popUpAppender);
  }
  if (process.env.NODE_ENV === 'production') {
    const baseUrl = process.env.VUE_APP_APIURL;
    const ajaxAppender = new log4Javascript.AjaxAppender(`${baseUrl}/Logs/Write`, true);
    ajaxAppender.setLayout(new log4Javascript.JsonLayout());
    ajaxAppender.addHeader('Content-Type', 'application/json; charset=utf-8');
    log.addAppender(ajaxAppender);
    log.info('logger loaded');
  }

  return {
    log,
  };
};
