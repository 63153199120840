<template>
  <div class="help-menu">
    <v-menu v-model="menu" bottom left offset-x rounded>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mb-10 slide-in-right delay-5"
          color="primary"
          icon
          right
          bottom
          fixed
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ mdiHelpCircle }}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item :href="youtubeTutorialUrl" target="_blank">
          <v-list-item-content>
            <v-list-item-title>Watch Tutorial</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mdiHelpCircle } from '@mdi/js';

export default {
  name: 'HelpMenu',
  data() {
    return {
      menu: false,
      youtubeTutorialUrl: 'https://www.youtube.com/embed/K0fJSrXZqW8',
      mdiHelpCircle,
    };
  },
};
</script>

<style scoped>
.help-menu .v-menu__content {
  background-color: #f5f0f3 !important;
  border-top-left-radius: 28px !important;
  border-bottom-left-radius: 28px !important;
}
.help-item {
  padding: 5px;
  padding-left: 10px;
  padding-right: 40px;
  margin-right: -30px;
}
</style>
