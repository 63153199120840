import { useAuth } from '../../../use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const auth = useAuth();
const actions = new Actions('synonymSearchResult', 'synonymSearchResults');

const customActions = {
  async LOAD_synonymSearchResults({ commit, rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('synonymSearchResults')
      .query({ $filter: 'IsDeleted eq false AND IsActive eq true' });
    commit('SET_synonymSearchResults', data);
    return data;
  },
};

export default { ...actions, ...customActions };
