import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import vuesocial from '@growthbunker/vuesocial';
import auroraModule from '@codehq/aurora-app-core/src/plugins/auroraModule';
import { components } from '@codehq/aurora-app-core';
import LocaleSwitcher from '@codehq/aurora-app-core/src/components/LocaleSwitcher.vue';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import VuetifyConfirm from 'vuetify-confirm';
import { o } from 'odata';
import App from './App.vue';
import VueOwinAuth from './plugins/vueOwinAuth';
import vuetify from './plugins/vuetify';
import store from './store';
import { router } from './router';
import { i18n } from './i18n';

dayjs.extend(relativeTime);
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

Vue.config.productionTip = false;

Vue.use(VueOwinAuth, {
  url: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});

Vue.use(VueAxios, axios);
Vue.use(VuetifyConfirm, { vuetify });
Vue.use(vuesocial);

const { ImageUpload } = components;
// register aurora components and plugin
Vue.component(LocaleSwitcher.name, LocaleSwitcher);
Vue.component(TableBasicButtons.name, TableBasicButtons);
Vue.component(ImageUpload.name, ImageUpload);
Vue.use(auroraModule, {
  baseUrl: process.env.VUE_APP_APIURL,
  store,
});

// format
Vue.mixin({
  methods: {
    formatDateTime(dt) {
      return dayjs(dt).format('YYYY-MM-DD HH:mm');
    },
    formatDateTimeSeconds(dt) {
      return dayjs(dt).format('YYYY-MM-DD HH:mm:ss');
    },
    formatDate(dt) {
      return dayjs(dt).format('YYYY-MM-DD');
    },
    formatFromNow(dt) {
      return dayjs(dt).fromNow();
    },
    formatRelative(dt) {
      return dayjs(dt).fromNow();
    },
  },
});

// Setup API
store.$http = axios;

o(process.env.VUE_APP_APIURL);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
  i18n,
}).$mount('#app');
