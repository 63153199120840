<template>
  <v-overlay :value="network.busy">
    <v-card>
      <v-card-title>Logging you back in...</v-card-title>
      <v-card-text class="text-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
/* eslint-disable indent */
import { mapActions, mapGetters, mapState } from 'vuex';
import axios from 'axios';
import authHub from '@codehq/aurora-app-core/src/authHub';
import { useAuth } from '../use';

const auth = useAuth();

export default {
  name: 'Auth',
  data() {
    return {
      network: {
        busy: false,
        retries: 0,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isExpired', 'getToken']),
    ...mapState('auth', ['token_expires', 'refresh_token', 'token']),
    ...mapState('organizations', ['organization']),
  },
  methods: {
    ...mapActions('auth', ['INIT_auth', 'LOGOUT_auth', 'REFRESH_auth']),
    async refreshToken() {
      this.$log.info('refreshing auth token');
      // refresh token expired
      await this.REFRESH_auth();
      this.$log.info('refreshed auth token');
    },
  },
  destroyed() {
    this.$root.$off('auth:token');
    this.$root.$off('auth:logoff');
  },
  async created() {
    this.$log.debug('setting up listeners');
    authHub.$on('network:unauthorized', async (n) => {
      this.$log.warn(n, this.network.busy);
      if (!this.network.busy) {
        if (this.network.retries > 1) {
          this.$root.$emit('auth:logoff');
        } else {
          // refresh token
          try {
            this.network.busy = true;
            this.network.retries += 1;
            this.$log.debug(`retries: ${this.network.retries}`);
            await this.REFRESH_auth();
            this.$root.$emit('page.refresh');
          } catch (error) {
            this.$log.error(error);
            setTimeout(() => {
              authHub.$emit('network:unauthorized', {
                source: 'retry',
              });
            }, 2000);
          } finally {
            this.network.busy = false;
          }
        }
      } else {
        this.$log.info('ignoring unauthorized request as the refresh token request is busy');
      }
    });
    this.$root.$on('auth:login', () => {
      this.network.retries = 0;
      this.$log.info('reset network retries');
    });
    this.$root.$on('auth:logoff', () => {
      this.LOGOUT_auth();
      auth.logout();
      this.$log.info('logged off');
    });
    this.$log.debug('setting up axios');
    axios.interceptors.request.use(
      async (config) => {
        const token = await auth.getAccessToken();
        const { organization } = this;
        const result = { ...config };
        if (token) {
          result.headers.Authorization = `Bearer ${token}`;
        }
        if (organization) {
          result.headers.organizationid = `${organization?.id ?? 3}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
    axios.interceptors.response.use(
      (response) => {
        this.network.retries = 0;
        this.$log.info('reset network retries');
        return response;
      },
      async (error) => {
        if (error) {
          if (error?.response?.status === 401) {
            authHub.$emit('network:unauthorized', {
              source: 'odata',
              data: error,
            });
          }
        } else {
          this.$log.error('Error not found');
          this.$root.$emit('auth:logoff');
        }
        return Promise.reject(error);
      },
    );
  },
};
</script>
