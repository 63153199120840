import { useAuth } from '../../../use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const auth = useAuth();
const actions = new Actions('tipAlternative', 'tipAlternatives');

const customActions = {
  async LOAD_tipAlternatives({ rootGetters, commit }, subCompetencyId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('tipAlternatives')
      .query({
        $filter: `IsDeleted eq false AND subCompetencyId eq ${subCompetencyId}`,
        $orderby: 'created desc',
        $expand: 'keywords($filter=IsDeleted eq false), questions($filter=IsDeleted eq false)',
      });
    commit('SET_tipAlternatives', data);
    return data;
  },
};

export default { ...actions, ...customActions };
