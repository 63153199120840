import { useAuth } from '../../../use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const auth = useAuth();
const actions = new Actions('keywordAlternative', 'keywordAlternatives');

const customActions = {
  async LOAD_keywordAlternatives({ rootGetters, commit }, keywordId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('keywordAlternatives')
      .query({
        $filter: `IsDeleted eq false AND keywordId eq ${keywordId}`,
        $orderby: 'created desc',
      });
    commit('SET_keywordAlternatives', data);
    return data;
  },
  async LOAD_keywordAlternativesAwaitingApproval({ rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('keywordAlternatives')
      .query({
        $filter: 'IsDeleted eq false AND IsActive eq false',
        $expand: 'Keyword($expand=Dimension($expand=SubCompetency($expand=Competency)))',
        $orderby: 'created desc',
      });

    return data;
  },
  async LOAD_allKeywordAlternatives({ rootGetters, commit }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('keywordAlternatives')
      .query({
        $filter: 'IsDeleted eq false',
        $orderby: 'created desc',
        $expand: 'Keyword($expand=Dimension($expand=SubCompetency($expand=Competency)))',
      });
    commit('SET_keywordAlternatives', data);
    return data;
  },
};

export default { ...actions, ...customActions };
