import { useAuth } from '../../../use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const auth = useAuth();
const actions = new Actions('question', 'questions');

const customActions = {
  async LOAD_questions({ rootGetters, commit }, dimensionId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('questions')
      .query({
        $filter: `IsDeleted eq false AND dimensionId eq ${dimensionId}`,
      });
    commit('SET_questions', data);
    return data;
  },
  async LOAD_dimensionQuestions({ rootGetters }, dimensionId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('questions')
      .query({
        $filter: `IsDeleted eq false AND DimensionId eq ${dimensionId}`,
      });
    return data;
  },
  async LOAD_subCompetencyQuestions({ rootGetters }, subCompetencyId) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('questions')
      .query({
        $filter: `IsDeleted eq false AND subCompetencyId eq ${subCompetencyId}`,
      });
    return data;
  },
  async SEARCH_questions({ rootGetters }, opt) {
    const { subCompetencyId, dimensionId } = opt;
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .post('questionSearch', {
        subCompetencyId,
        dimensionId,
      })
      .query();
    // commit('SET_keywords', data);
    return data;
  },
};

export default { ...actions, ...customActions };
