import { useAuth } from '../../../use/useAuth';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const auth = useAuth();
const actions = new Actions('competency', 'competencies');

const customActions = {
  async LOAD_competencies({ rootGetters, commit }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('competencies')
      .query({
        $filter: 'IsDeleted eq false',
        $orderby: 'created desc',
        $expand: 'SubCompetencies($expand=Questions($filter=IsDeleted eq false),Dimensions($expand=Keywords($filter=IsDeleted eq false)))',
      });
    commit('SET_competencies', data);
    return data;
  },
  async LOAD_competenciesAndSearch({ rootGetters, commit }, keyword) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('competencies')
      .query({
        $filter: 'IsDeleted eq false',
        $orderby: 'created desc',
        $expand: `SubCompetencies($expand=Questions($filter=IsDeleted eq false),Dimensions($expand=Keywords($filter=IsDeleted eq false AND contains(content, '${keyword}')),Questions($expand=Tips($filter=IsDeleted eq false))))`,
      });
    commit('SET_competencies', data);
    return data;
  },
};

export default { ...actions, ...customActions };
