export default {
  keywords: [{
    id: 161500,
    word: 'courageous',
    competency: {
      id: 161,
      name: 'entrepreneurship',
    },
  }, {
    id: 161501,
    word: 'brave',
    competency: {
      id: 161,
      name: 'entrepreneurship',
    },
  }, {
    id: 161502,
    word: 'risk-taking',
    competency: {
      id: 161,
      name: 'entrepreneurship',
    },
  }, {
    id: 161503,
    word: 'confident',
    competency: {
      id: 161,
      name: 'entrepreneurship',
    },
  }, {
    id: 161504,
    word: 'determined',
    competency: {
      id: 161,
      name: 'entrepreneurship',
    },
  }, {
    id: 111200,
    word: 'courageous',
    competency: {
      id: 111,
      name: 'decision-making & risk taking ability',
    },
  }, {
    id: 111201,
    word: 'fearless',
    competency: {
      id: 111,
      name: 'decision-making & risk taking ability',
    },
  }, {
    id: 111202,
    word: 'daring',
    competency: {
      id: 111,
      name: 'decision-making & risk taking ability',
    },
  }, {
    id: 111203,
    word: 'brave',
    competency: {
      id: 111,
      name: 'decision-making & risk taking ability',
    },
  }, {
    id: 111204,
    word: 'risk-taking',
    competency: {
      id: 111,
      name: 'decision-making & risk taking ability',
    },
  },
  ],
  keyword: undefined,
};
