<template>
  <v-footer app fixed color="#8c6384" height="35px">
    <v-row no-gutters>
        <v-col>
          <v-btn icon href="https://www.facebook.com" class="slide-in-bottom delay-1">
            <v-icon color="white">{{ mdiFacebook }}</v-icon>
          </v-btn>
          <v-btn icon href="https://www.instagram.com" class="slide-in-bottom delay-2">
            <v-icon color="white">{{ mdiInstagram }}</v-icon>
          </v-btn>
          <v-btn icon href="https://www.linkedin.com" class="slide-in-bottom delay-3">
            <v-icon color="white">{{ mdiLinkedin }}</v-icon>
          </v-btn>
        </v-col>
        <v-col class="col-links text-center">
          <span class="font-weight-bold mr-1">Beta Version:</span>
          <span>v{{ version }}</span>
        </v-col>
        <v-col class="col-links" align="right">
          <a href="#">Privacy Policy</a> |
          <a href="#">Subscription Policy</a> |
          <a href="#">Terms & Conditions</a>
        </v-col>
      </v-row>
  </v-footer>
</template>

<script>
import { mdiFacebook, mdiInstagram, mdiLinkedin } from '@mdi/js';
import pkg from '../../package.json';

export default {
  data() {
    return {
      mdiFacebook,
      mdiInstagram,
      mdiLinkedin,
      version: pkg.version ?? '0.0.0',
    };
  },
};
</script>
<style scoped>
.v-footer {
  padding: 0;
  color: #c9b7c6;
}
.v-footer a {
  color: #c9b7c6;
  font-size: small;
}
.v-footer span {
  color: #c9b7c6;
  font-size: small;
}
.col-links {
  padding-right: 20px !important;
  padding-top: 5px !important;
}
</style>
