import Mutations from '../../odata/mutations';

export default {
  ...new Mutations('skill'),
  ...new Mutations('skills'),
  ...new Mutations('selectedSkills'),
  INSERT_selectedSkills(state, skill) {
    state.selectedSkills.push(skill);
  },
  REMOVE_selectedSkill(state, skill) {
    const index = state.selectedSkills
      .findIndex((s) => s.words === skill.words && s.searchWord === skill.searchWord);
    state.selectedSkills.splice(index, 1);
  },
  CLEAR_selectedSkills(state) {
    state.selectedSkills = [];
  },
  UPDATE_selectedSkill(state, skill) {
    const index = state.selectedSkills
      .findIndex((s) => s.words === skill.words && s.searchWord === skill.searchWord);
    state.selectedSkills.splice(index, 1, skill);
  },
};
