import { useAuth } from '../../../use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const auth = useAuth();
const actions = new Actions('skillSet', 'skillSets');

const customActions = {
  async LOAD_skillSets({ commit, rootGetters }) {
    const username = rootGetters['auth/getUsername'];
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('skillSets')
      .query({ $filter: `IsDeleted eq false AND CreatedBy eq '${username}'` });
    commit('SET_skillSets', data);
    return data;
  },
  async LOAD_favouriteSkillSets({ commit, rootGetters }) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('skillSets/GetFavourites')
      .query();
    commit('SET_skillSets', data);
    return data;
  },
  async LOAD_skillSetExpanded({ commit, rootGetters }, id) {
    const token = await auth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get(`skillSets(${id})`)
      .query({ $expand: 'Skills($expand=SkillItems)' });
    commit('SET_skillSet', data);
    return data;
  },
};

export default { ...actions, ...customActions };
